import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

const registerLogsUser = async (log: string) => {
  configApi.post(`auth/log-history/`, undefined, { params: { access: log } });
};

export const useLogsUser = (log: string) => {
  return useQuery({
    queryKey: ['register_log_user'],
    queryFn: () => registerLogsUser(log),
  });
};
